import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import {
  faQuoteLeft,
  faQuoteRight,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TestimonialsBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language, link, title } = useParams();

  const develpment = useAppSelector((state) => state.page.development);
  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  const [doctorsIndex, setDoctorsIndex] = useState(0);
  const [doctorsMaxIndex, setDoctorsMaxIndex] = useState(0);

  const doctorsSlider = () => {
    if (doctorsIndex === doctorsMaxIndex - 1) {
      setDoctorsIndex(0);
      scrollTestimonialContainer(0);
    } else {
      setDoctorsIndex(doctorsIndex + 1);
      scrollTestimonialContainer(doctorsIndex + 1);
    }
  };

  const [testimonialScrollActive, setTestimonialScrollActive] = useState(0);

  const scrollTestimonialContainer = (offset: number) => {
    // if (testimonialScrollDisabled) return;

    const container = document.getElementById("testimonial-container");
    container!.scrollLeft = container!.clientWidth * offset;
    // setTestimonialScrollDisabled(true);
    setDoctorsIndex(offset);
    setTestimonialScrollActive(offset);
    // setTimeout(() => {
    //   setTestimonialScrollDisabled(false);
    // }, 1000);
  };

  useEffect(() => {
    setDoctorsMaxIndex(
      blocks.filter((block) => block.connector === "testimonial").length
    );
  }, [blocks]);

  useEffect(() => {
    if (doctorsMaxIndex <= 0) return;
    const timer = setTimeout(() => {
      doctorsSlider();
    }, 5000);

    return () => clearTimeout(timer);
  }, [doctorsMaxIndex]);

  useEffect(() => {
    if (doctorsMaxIndex <= 0) return;
    const timer = setTimeout(() => {
      doctorsSlider();
    }, 5000);

    return () => clearTimeout(timer);
  }, [doctorsIndex]);

  return (
    <div
      style={{
        backgroundImage: develpment
          ? "#"
          : `url(https://api.uemurachiropractic.com/uploads/${
              blocks.filter((block) => block.connector === "testimonials")[0]
                .image
            })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div style={{ backgroundColor: "rgba(144, 198, 177, 0.9)" }}>
        <div className="flex flex-col w-full max-w-screen-xl m-auto p-8 sm:p-16">
          <div
            id="testimonial-container"
            className="hide-scrollbar flex w-full overflow-hidden"
            onScroll={(event) => {
              event.preventDefault();
            }}
          >
            {blocks
              .filter(
                (block) =>
                  block.navigation_id ===
                  navigations
                    .filter((navigation) => navigation.code === language)
                    .filter((navigation) => navigation.indicator === "home")[0]
                    .navigation_id
              )
              .filter((block) => block.connector === "testimonial")
              .map((block, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center min-w-full text-white"
                >
                  <div
                    className="w-20 h-20 rounded-full"
                    style={{
                      backgroundImage: develpment
                        ? "#"
                        : `url(https://api.uemurachiropractic.com/uploads/${block.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <div className="my-10 relative italic text-xl text-center">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: block.contents.filter(
                          (content: any) => content.code === language
                        )[0].description,
                      }}
                    />
                    <span className="absolute top-0 left-0 -translate-y-10 text-4xl">
                      <FontAwesomeIcon icon={faQuoteLeft} />
                    </span>
                    <span className="absolute bottom-0 right-0 translate-y-10 text-4xl">
                      <FontAwesomeIcon icon={faQuoteRight} />
                    </span>
                  </div>
                  <div
                    className="italic text-xl"
                    dangerouslySetInnerHTML={{
                      __html: block.contents.filter(
                        (content: any) => content.code === language
                      )[0].title,
                    }}
                  />
                </div>
              ))}
          </div>
          <div className="flex gap-2 justify-center my-10 text-white">
            {blocks
              .filter(
                (block) =>
                  block.navigation_id ===
                  navigations
                    .filter((navigation) => navigation.code === language)
                    .filter((navigation) => navigation.indicator === "home")[0]
                    .navigation_id
              )
              .filter((block) => block.connector === "testimonial")
              .map((block, index) => (
                <span
                  className="cursor-pointer"
                  style={
                    testimonialScrollActive === index
                      ? { color: "#fff" }
                      : { color: "rgba(255, 255, 255, 0.4)" }
                  }
                  onClick={() => scrollTestimonialContainer(index)}
                >
                  <FontAwesomeIcon icon={faCircle} />
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsBlock;
