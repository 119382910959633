import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import {
  updateActiveNavigationIndexState,
  updateViewBlockState,
} from "../slice";

const ConditionViewBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language, link, title } = useParams();

  const develpment = useAppSelector((state) => state.page.development);

  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  return (
    <div className="">
      <div className="flex flex-col gap-16 w-full max-w-screen-xl m-auto p-8">
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex-1">
            <img
              className="w-full rounded-3xl shadow-2xl"
              src={
                develpment
                  ? "#"
                  : `https://api.uemurachiropractic.com/uploads/${
                      blocks
                        .filter(
                          (block) =>
                            block.navigation_id ===
                            navigations.filter(
                              (navigation) => navigation.link === link
                            )[0].navigation_id
                        )
                        .filter((block) =>
                          block.contents.some(
                            (content) =>
                              content.title === decodeURIComponent(title!)
                          )
                        )[0].image
                    }`
              }
              alt=""
            />
            <div
              className="my-8 font-bold text-3xl"
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations.filter(
                        (navigation) => navigation.link === link
                      )[0].navigation_id
                  )
                  .filter((block) =>
                    block.contents.some(
                      (content) => content.title === decodeURIComponent(title!)
                    )
                  )[0]
                  .contents.filter((content) => content.code === language)[0]
                  .description,
              }}
            />
            <div
              className="my-8 whitespace-pre-line"
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations.filter(
                        (navigation) => navigation.link === link
                      )[0].navigation_id
                  )
                  .filter((block) =>
                    block.contents.some(
                      (content) => content.title === decodeURIComponent(title!)
                    )
                  )[0]
                  .contents.filter((content) => content.code === language)[0]
                  .description2,
              }}
            />
            {/* <div
          className="my-8 font-bold text-xl"
          dangerouslySetInnerHTML={{
            __html: viewBlock!.contents.filter(content => content.code === language)[0].sub_title,
          }}
        /> */}
            <a
              onClick={() => {
                navigate(
                  `/${language}/${
                    navigations
                      .filter((navigation) => navigation.code === language)
                      .filter(
                        (navigation) => navigation.indicator === "contact-us"
                      )[0].link
                  }${title ? `/${title}` : ""}`
                );
                dispatch(
                  updateActiveNavigationIndexState({
                    value: navigations
                      .filter((navigation) => navigation.code === language)
                      .findIndex(
                        (navigation) => navigation.indicator === "contact-us"
                      ),
                  })
                );
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <button
                className="btn h-auto rounded-full border-0 outline-none text-white px-8 self-end"
                style={{ backgroundColor: "#90C6B1" }}
              >
                {
                  blocks
                    .filter(
                      (block) =>
                        block.navigation_id ===
                        navigations.filter(
                          (navigation) => navigation.link === link
                        )[0].navigation_id
                    )
                    .filter((block) =>
                      block.contents.some(
                        (content) =>
                          content.title === decodeURIComponent(title!)
                      )
                    )[0]
                    .contents.filter((content) => content.code === language)[0]
                    .button_text
                }
              </button>
            </a>
          </div>
          <div className="min-w-60">
            <div className="bg-gray-300 p-4 rounded-2xl">
              <div
                className="mb-4 font-bold text-xl"
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter(
                      (block) =>
                        block.navigation_id ===
                        navigations.filter(
                          (navigation) => navigation.link === link
                        )[0].navigation_id
                    )
                    .filter((block) =>
                      block.contents.some(
                        (content) =>
                          content.title === decodeURIComponent(title!)
                      )
                    )[0]
                    .contents.filter((content) => content.code === language)[0]
                    .description3,
                }}
              />
              <div className="flex flex-col">
                {blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations.filter(
                        (navigation) => navigation.link === link
                      )[0].navigation_id
                  )
                  .filter((block) => block.connector === "")
                  .map((condition: any) => (
                    <a
                      className="border-t cursor-pointer border-gray-400 first:border-0"
                      onClick={() => {
                        updateViewBlockState(condition);
                        navigate(
                          `/${language}/${link}/${encodeURIComponent(
                            condition.contents.filter(
                              (content: any) => content.code === language
                            )[0].title
                          )}`
                        );
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <div className="py-2">
                        {
                          condition.contents.filter(
                            (content: any) => content.code === language
                          )[0].title
                        }
                      </div>
                    </a>
                  ))}
              </div>
            </div>
            <div className="bg-gray-300 mt-16 p-4 rounded-2xl">
              <div
                className="mb-4 font-bold text-xl"
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter(
                      (block) =>
                        block.navigation_id ===
                        navigations.filter(
                          (navigation) => navigation.link === link
                        )[0].navigation_id
                    )
                    .filter((block) =>
                      block.contents.some(
                        (content) =>
                          content.title === decodeURIComponent(title!)
                      )
                    )[0]
                    .contents.filter((content) => content.code === language)[0]
                    .description4,
                }}
              />
              <div className="flex flex-col">
                {blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations.filter(
                        (navigation) => navigation.link === link
                      )[0].navigation_id
                  )
                  .filter((block) => block.connector === "other")
                  .map((condition: any) => (
                    <a
                      className="border-t cursor-pointer border-gray-400 first:border-0"
                      onClick={() => {
                        updateViewBlockState(condition);
                        navigate(
                          `/${language}/${link}/${encodeURIComponent(
                            condition.contents.filter(
                              (content: any) => content.code === language
                            )[0].title
                          )}`
                        );
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <div className="py-2">
                        {
                          condition.contents.filter(
                            (content: any) => content.code === language
                          )[0].title
                        }
                      </div>
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionViewBlock;
