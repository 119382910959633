import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AdminNavigation from "./components/AdminNavigation";

const AdminNavigations = () => {
  const [requestUpdateBanner, setRequestUpdateBanner] =
    useState<boolean>(false);
  const [requestProcessing, setRequestProcessing] = useState<boolean>(false);
  const [languageIndex, setLanguageIndex] = useState(0);
  const [navigations, setNavigations] = useState([]);

  const getNavigations = async () => {
    try {
      const response = await fetch(
        `https://api.uemurachiropractic.com/get-admin-navigations.php`,
        {
          method: "GET",
        }
      );
      setNavigations(JSON.parse(await response.text()));
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
    }
  };

  const [languages, setLanguages] = useState([]);

  const getLanguages = async () => {
    try {
      const response = await fetch(
        "https://api.uemurachiropractic.com/get-languages.php",
        {
          method: "GET",
        }
      );
      setLanguages(JSON.parse(await response.text()));
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    navigationIndex: number,
    contentIndex: number
  ) => {
    const { name, value } = e.target as { name: string; value: any };
    setNavigations((prevData) => {
      const updatedBlocks = [...prevData];
      if (name === "connector")
        (updatedBlocks[navigationIndex] as any)[name] = value;
      else
        (updatedBlocks[navigationIndex] as any).contents[contentIndex][name] =
          value;
      return updatedBlocks;
    });
  };

  const handleUpdateBanner = async (
    e: React.ChangeEvent<HTMLInputElement>,
    navigationIndex: number
  ) => {
    const files = (e.target as HTMLInputElement).files;

    if (requestUpdateBanner) {
      return;
    }

    try {
      setRequestUpdateBanner(true);
      const data = new FormData();
      data.append("id", (navigations[navigationIndex] as any).id);
      data.append("banner", files![0]);

      await fetch(
        "https://api.uemurachiropractic.com/update-navigation-banner.php",
        {
          method: "POST",
          body: data,
        }
      );

      toast.success("Update banner success.");
      await getNavigations();
      setRequestUpdateBanner(false);
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
      setRequestUpdateBanner(false);
    }
  };

  const handleOnSubmit = async (
    e: React.FormEvent,
    navigationIndex: number,
    contentIndex: number
  ) => {
    e.preventDefault();

    if (requestProcessing) {
      return;
    }

    try {
      setRequestProcessing(true);
      const data = new FormData();
      data.append("navigation_id", (navigations[navigationIndex] as any).id);
      data.append("connector", (navigations[navigationIndex] as any).connector);
      data.append(
        "id",
        (navigations[navigationIndex] as any).contents[contentIndex].id
      );
      data.append(
        "link",
        (navigations[navigationIndex] as any).contents[contentIndex].link
      );
      data.append(
        "text",
        (navigations[navigationIndex] as any).contents[contentIndex].text
      );
      data.append(
        "title",
        (navigations[navigationIndex] as any).contents[contentIndex].title
      );
      // data.append(
      //   "sub_title",
      //   (navigations[navigationIndex] as any).contents[contentIndex].sub_title
      // );
      data.append(
        "description",
        (navigations[navigationIndex] as any).contents[contentIndex].description
      );
      // data.append(
      //   "button_label",
      //   (navigations[navigationIndex] as any).contents[contentIndex]
      //     .button_label
      // );
      data.append(
        "button_text",
        (navigations[navigationIndex] as any).contents[contentIndex].button_text
      );
      data.append(
        "button_link",
        (navigations[navigationIndex] as any).contents[contentIndex].button_link
      );

      await fetch(
        "https://api.uemurachiropractic.com/update-navigation-content.php",
        {
          method: "POST",
          body: data,
        }
      );

      toast.success("Update success.");
      await getNavigations();
      setRequestProcessing(false);
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
      setRequestProcessing(false);
    }
  };

  useEffect(() => {
    getNavigations();
    getLanguages();
  }, []);

  return (
    <div className="flex">
      <AdminNavigation />
      <div className="flex flex-col gap-4 flex-1 h-screen p-4 bg-gray-200 overflow-auto">
        <h1 className="mr-auto font-bold text-2xl">Navigations</h1>
        <hr className="border-2 border-gray-800" />
        {navigations.length === 0 || languages.length === 0 ? (
          <span className="loading loading-dots loading-lg m-auto"></span>
        ) : (
          navigations.map((navigation, navigationIndex) => {
            return (
              <div
                className="collapse bg-white overflow-visible"
                key={navigationIndex}
              >
                <input type="checkbox" />
                <div className="collapse-title text-xl font-medium">
                  {(navigation as any).name}
                </div>
                <div className="collapse-content">
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-4">
                      <div className="flex flex-col gap-4 flex-1">
                        <div>
                          <label htmlFor="banner">Update Banner</label>
                          <input
                            name="banner"
                            type="file"
                            className="file-input file-input-bordered w-full"
                            disabled={requestUpdateBanner}
                            onChange={(e) => {
                              handleUpdateBanner(e, navigationIndex);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex-1 flex p-4">
                        {requestUpdateBanner ? (
                          <span className="loading loading-dots loading-lg m-auto"></span>
                        ) : (
                          <img
                            className="w-full max-h-80 m-auto object-contain"
                            src={`https://api.uemurachiropractic.com/uploads/${
                              (navigation as any).banner
                            }`}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div role="tablist" className="tabs tabs-lifted">
                      {languages.map((lang, langIndex) => {
                        return (
                          <span
                            role="tab"
                            className={`tab ${
                              languageIndex === langIndex
                                ? "tab-active [--tab-bg:darkblue] text-white"
                                : ""
                            }`}
                            onClick={() => setLanguageIndex(langIndex)}
                          >
                            {(lang as any).name}
                          </span>
                        );
                      })}
                    </div>
                    {(navigation as any).contents.map(
                      (content: any, contentIndex: number) => {
                        return (
                          <form
                            onSubmit={(e) => {
                              handleOnSubmit(e, navigationIndex, contentIndex);
                            }}
                            className={`flex flex-col gap-4 ${
                              languageIndex !== contentIndex ? "hidden" : ""
                            }`}
                            key={contentIndex}
                          >
                            <div className="flex-1">
                              <label htmlFor="link">Link</label>
                              <input
                                name="link"
                                className="input input-bordered w-full"
                                defaultValue={content.link}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    navigationIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <label htmlFor="text">Text</label>
                              <input
                                name="text"
                                className="input input-bordered w-full"
                                defaultValue={content.text}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    navigationIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <label htmlFor="title">Title</label>
                              <input
                                name="title"
                                className="input input-bordered w-full"
                                defaultValue={content.title}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    navigationIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            {/* <div className="flex-1">
                              <label htmlFor="sub_title">Sub-title</label>
                              <input
                                name="sub_title"
                                className="input input-bordered w-full"
                                defaultValue={content.sub_title}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    navigationIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div> */}
                            <div className="flex-1">
                              <label htmlFor="description">Description 1</label>
                              <textarea
                                name="description"
                                className="textarea textarea-bordered w-full resize-none"
                                rows={8}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    navigationIndex,
                                    contentIndex
                                  );
                                }}
                              >
                                {content.description}
                              </textarea>
                            </div>
                            {/* <div className="flex-1">
                              <label htmlFor="button_label">Button label</label>
                              <input
                                name="button_label"
                                className="input input-bordered w-full"
                                defaultValue={content.button_label}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    navigationIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div> */}
                            <div className="flex-1">
                              <label htmlFor="button_text">Button text</label>
                              <input
                                name="button_text"
                                className="input input-bordered w-full"
                                defaultValue={content.button_text}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    navigationIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-1">
                              <label htmlFor="button_link">Button link</label>
                              <input
                                name="button_link"
                                className="input input-bordered w-full"
                                defaultValue={content.button_link}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    navigationIndex,
                                    contentIndex
                                  );
                                }}
                              />
                            </div>
                            <div className="flex gap-2 justify-end">
                              <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={requestProcessing}
                              >
                                {requestProcessing ? "Please Wait..." : "Save"}
                              </button>
                            </div>
                          </form>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AdminNavigations;
