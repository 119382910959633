import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import { updateActiveNavigationIndexState } from "../slice";

const Info1Block = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const develpment = useAppSelector((state) => state.page.development);
  const { language, title } = useParams();

  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  return (
    <div
      className="info-1-block"
      style={{
        backgroundColor: "#90C6B1",
      }}
    >
      <div className="portions-container">
        <div className="left-portion">
          <div
            className="image"
            style={{
              backgroundImage: develpment
                ? "#"
                : `url(https://api.uemurachiropractic.com/uploads/${
                    blocks.filter((block) => block.connector === "info-1")[0]
                      .image
                  })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
        <div className="right-portion">
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: blocks
                .filter((block) => block.connector === "info-1")[0]
                .contents.filter((content) => content.code === language)[0]
                .title,
            }}
          />
          <div className="white-bar"></div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: blocks
                .filter((block) => block.connector === "info-1")[0]
                .contents.filter((content) => content.code === language)[0]
                .description,
            }}
          />
          <button
            style={{ color: "#90C6B1" }}
            onClick={() => {
              navigate(
                `/${language}/${
                  navigations
                    .filter((navigation) => navigation.code === language)
                    .filter(
                      (navigation) => navigation.indicator === "condition"
                    )[0].link
                }${title ? `/${title}` : ""}`
              );
              dispatch(
                updateActiveNavigationIndexState({
                  value: navigations
                    .filter((navigation) => navigation.code === language)
                    .findIndex(
                      (navigation) => navigation.indicator === "condition"
                    ),
                })
              );
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {
              blocks
                .filter((block) => block.connector === "info-1")[0]
                .contents.filter((content) => content.code === language)[0]
                .button_text
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default Info1Block;
