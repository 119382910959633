import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type InitialState = {
  formProcessing: boolean;
  form: LoginFormState;
};

export type LoginFormState = {
  username: string;
  password: string;
};

const initialState: InitialState = {
  formProcessing: false,
  form: {
    username: "",
    password: "",
  },
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    updateLoginFormProcessingState: (
      state,
      action: PayloadAction<{
        value: boolean;
      }>
    ) => {
      state.formProcessing = action.payload.value;
    },
    updateLoginFormState: (
      state,
      action: PayloadAction<{
        name: string;
        value: string | number | undefined;
      }>
    ) => {
      const { name, value } = action.payload;
      state.form = {
        ...state.form,
        [name]: value,
      };
    },
  },
});

const loginReducer = loginSlice.reducer;

export const { updateLoginFormProcessingState, updateLoginFormState } =
  loginSlice.actions;
export default loginReducer;
