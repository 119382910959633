import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYelp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import { toast } from "react-toastify";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { updateActiveNavigationIndexState } from "../slice";

const ContactUsBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language, link, title } = useParams();

  const develpment = useAppSelector((state) => state.page.development);

  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const [requestProcessing, setRequestProcessing] = useState(false);

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (requestProcessing) {
      return;
    }

    try {
      setRequestProcessing(true);
      const data = new FormData();
      data.append("name", formData.name);
      data.append("email", formData.email);
      data.append("message", formData.message);
      data.append("phone", formData.phone);

      const response = await fetch(
        "https://api.uemurachiropractic.com/mailer.php",
        {
          method: "POST",
          body: data,
        }
      );

      const result = await response.text();
      if (response.ok) {
        toast.success("Email sent successfully.");
        setRequestProcessing(false);
      } else {
        setRequestProcessing(false);
        throw new Error(result);
      }
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
      setRequestProcessing(false);
    }
  };

  return (
    <div className="bg-stone-800 text-white">
      <div className="w-full max-w-screen-xl m-auto p-4 sm:p-16">
        <div className="flex-col gap-16 sm:flex sm:flex-row">
          <div className="flex-1 mb-16 sm:mb-0">
            <div className="flex flex-col gap-4 mb-4">
              <div className="flex gap-2 items-center">
                <a href="tel:(424) 201-0036">
                  <FontAwesomeIcon className="text-3xl" icon={faPhone} />
                </a>
                <div className="flex flex-col">
                  <span>Call/Text Us Today!</span>
                  <span>(424) 201-0036</span>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <FontAwesomeIcon className="text-3xl" icon={faLocationDot} />
                <div className="flex flex-col">
                  <a href="https://www.google.com/maps/place/Leo+Uemura+Chiropractic/@33.8324208,-118.3698424,15z/data=!4m10!1m2!2m1!1sLeo+Uemura+Chiropractic!3m6!1s0x80dd4b308cf91e71:0xa1e2322e149c8527!8m2!3d33.8324208!4d-118.3533629!15sChdMZW8gVWVtdXJhIENoaXJvcHJhY3RpY1oZIhdsZW8gdWVtdXJhIGNoaXJvcHJhY3RpY5IBDGNoaXJvcHJhY3RvcpoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VNdGRuTnRVRUozRUFF4AEA!16s%2Fg%2F11pyc22s60">
                    <span>
                      21535 Hawthorne Blvd, Suite 270, Torrance, CA 90503
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="underline font-bold text-2xl"
              style={{ color: "#90C6B1" }}
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter((block) => block.connector === "footer")[0]
                  .contents.filter((content) => content.code === language)[0]
                  .title,
              }}
            />
            <div className="w-full overflow-auto flex py-8">
              {blocks
                .filter(
                  (block) =>
                    block.navigation_id ===
                    navigations
                      .filter((navigation) => navigation.code === language)
                      .filter(
                        (navigation) => navigation.indicator === "home"
                      )[0].navigation_id
                )
                .filter((block) => block.connector === "footer-schedule")
                .map((block, index) => (
                  <div className="min-w-32 sm:min-w-fit px-2 sm:px-4 border-l border-white text-center first:border-l-0">
                    <div
                      className="font-bold"
                      dangerouslySetInnerHTML={{
                        __html: block.contents.filter(
                          (content: any) => content.code === language
                        )[0].title,
                      }}
                    />
                    <div
                      className="text-sm"
                      dangerouslySetInnerHTML={{
                        __html: block.contents.filter(
                          (content: any) => content.code === language
                        )[0].description,
                      }}
                    />
                    <div
                      className="text-sm"
                      dangerouslySetInnerHTML={{
                        __html: block.contents.filter(
                          (content: any) => content.code === language
                        )[0].description2,
                      }}
                    />
                  </div>
                ))}
            </div>
            <img
              className="w-40 block m-auto lg:m-0"
              src={
                develpment
                  ? "#"
                  : `https://api.uemurachiropractic.com/uploads/${
                      blocks.filter((block) => block.connector === "footer")[0]
                        .image
                    }`
              }
              alt=""
            />
            <div
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter((block) => block.connector === "footer")[0]
                  .contents.filter((content) => content.code === language)[0]
                  .description,
              }}
            />
            <div className="flex gap-8 my-8 text-xl justify-center lg:justify-start">
              <a href="https://www.facebook.com/leouemurachiropractic?mibextid=cr9u03">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://www.instagram.com/uemura_chiropractic?igsh=MXAydGhtOGltZ2cxdw%3D%3D&fbclid=IwAR0lcnVZ1d36iAHh0afifRP8jDoJEwbTbCyoPoFJ5NfzSTjtE2tmQ5bUiHw_aem_ATimI78OIrI2hW-ytqnh-p1hEGzwwozyIBWJPzB674LhoXZc7gG4io4YxihCYaV5feI54iEjgKcZGzB_rN-x50q7">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
            {/* <div className="flex gap-8 underline">
              <span
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter((block) => block.connector === "footer")[0]
                    .contents.filter((content) => content.code === language)[0]
                    .description2,
                }}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter((block) => block.connector === "footer")[0]
                    .contents.filter((content) => content.code === language)[0]
                    .description3,
                }}
              />
            </div> */}
          </div>
          <form
            onSubmit={handleOnSubmit}
            className="flex flex-col gap-4 sm:min-w-96"
          >
            <div
              className="underline font-bold text-2xl mb-4"
              style={{ color: "#90C6B1" }}
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations
                        .filter((navigation) => navigation.code === language)
                        .filter(
                          (navigation) => navigation.indicator === "home"
                        )[0].navigation_id
                  )
                  .filter((block) => block.connector === "footer-form")[0]
                  .contents.filter(
                    (content: any) => content.code === language
                  )[0].title,
              }}
            />
            <input
              name="name"
              onChange={handleOnChange}
              value={formData.email}
              required
              className="input w-full rounded-full text-black"
              placeholder={
                blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations
                        .filter((navigation) => navigation.code === language)
                        .filter(
                          (navigation) => navigation.indicator === "home"
                        )[0].navigation_id
                  )
                  .filter((block) => block.connector === "footer-form")[0]
                  .contents.filter(
                    (content: any) => content.code === language
                  )[0].sub_title
              }
            />
            <input
              name="email"
              onChange={handleOnChange}
              value={formData.email}
              required
              className="input w-full rounded-full text-black"
              placeholder={
                blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations
                        .filter((navigation) => navigation.code === language)
                        .filter(
                          (navigation) => navigation.indicator === "home"
                        )[0].navigation_id
                  )
                  .filter((block) => block.connector === "footer-form")[0]
                  .contents.filter(
                    (content: any) => content.code === language
                  )[0].description
              }
            />
            <input
              name="phone"
              onChange={handleOnChange}
              value={formData.phone}
              required
              className="input w-full rounded-full text-black"
              placeholder={
                blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations
                        .filter((navigation) => navigation.code === language)
                        .filter(
                          (navigation) => navigation.indicator === "home"
                        )[0].navigation_id
                  )
                  .filter((block) => block.connector === "footer-form")[0]
                  .contents.filter(
                    (content: any) => content.code === language
                  )[0].description2
              }
            />
            <textarea
              name="message"
              onChange={handleOnChange}
              value={formData.message}
              required
              className="textarea w-full rounded-3xl resize-none text-black"
              rows={8}
              placeholder={
                blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations
                        .filter((navigation) => navigation.code === language)
                        .filter(
                          (navigation) => navigation.indicator === "home"
                        )[0].navigation_id
                  )
                  .filter((block) => block.connector === "footer-form")[0]
                  .contents.filter(
                    (content: any) => content.code === language
                  )[0].description3
              }
            ></textarea>
            <button
              type="submit"
              disabled={requestProcessing}
              className="btn h-auto rounded-full border-0 outline-none text-white px-8 self-end"
              style={{ backgroundColor: "#90C6B1" }}
            >
              {
                blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations
                        .filter((navigation) => navigation.code === language)
                        .filter(
                          (navigation) => navigation.indicator === "home"
                        )[0].navigation_id
                  )
                  .filter((block) => block.connector === "footer-form")[0]
                  .contents.filter(
                    (content: any) => content.code === language
                  )[0].button_text
              }
            </button>
            <div className="mt-4 flex self-center lg:self-end gap-4 justify-end">
              <a
                className="cursor-pointer"
                onClick={() => {
                  dispatch(updateActiveNavigationIndexState({ value: -1 }));
                  navigate(`/${language}/hippa-policy`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <span
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: blocks
                      .filter((block) => block.connector === "footer")[0]
                      .contents.filter(
                        (content) => content.code === language
                      )[0].description3,
                  }}
                />
              </a>
              <a
                className="cursor-pointer"
                onClick={() => {
                  dispatch(updateActiveNavigationIndexState({ value: -1 }));
                  navigate(`/${language}/privacy-policy`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <span
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: blocks
                      .filter((block) => block.connector === "footer")[0]
                      .contents.filter(
                        (content) => content.code === language
                      )[0].description4,
                  }}
                />
              </a>
            </div>
            <span
              className="mt-4 self-center lg:self-end"
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter(
                    (block) =>
                      block.navigation_id ===
                      navigations
                        .filter((navigation) => navigation.code === language)
                        .filter(
                          (navigation) => navigation.indicator === "home"
                        )[0].navigation_id
                  )
                  .filter((block) => block.connector === "footer-form")[0]
                  .contents.filter(
                    (content: any) => content.code === language
                  )[0].description4,
              }}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsBlock;
