import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import { updateActiveNavigationIndexState } from "../slice";
import { useDispatch } from "react-redux";

const WelcomeBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language, title } = useParams();

  const develpment = useAppSelector((state) => state.page.development);
  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  return (
    <div
      className="welcome-block"
      style={{
        backgroundImage: develpment
          ? "#"
          : `url(https://api.uemurachiropractic.com/uploads/${
              blocks.filter((block) => block.connector === "welcome")[0].image
            })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="content-container">
        <div className="portions-container">
          <div className="left-portion"></div>
          <div className="right-portion">
            <div
              className="title"
              style={{ color: "#90C6B1" }}
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter((block) => block.connector === "welcome")[0]
                  .contents.filter((content) => content.code === language)[0]
                  .title,
              }}
            />
            <div
              className="sub-title"
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter((block) => block.connector === "welcome")[0]
                  .contents.filter((content) => content.code === language)[0]
                  .sub_title,
              }}
            />
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter((block) => block.connector === "welcome")[0]
                  .contents.filter((content) => content.code === language)[0]
                  .description,
              }}
            />
            <button
              style={{ backgroundColor: "#90C6B1" }}
              onClick={() => {
                navigate(
                  `/${language}/${
                    navigations
                      .filter((navigation) => navigation.code === language)
                      .filter(
                        (navigation) => navigation.indicator === "contact-us"
                      )[0].link
                  }${title ? `/${title}` : ""}`
                );
                dispatch(
                  updateActiveNavigationIndexState({
                    value: navigations
                      .filter((navigation) => navigation.code === language)
                      .findIndex(
                        (navigation) => navigation.indicator === "contact-us"
                      ),
                  })
                );
              }}
            >
              {
                blocks
                  .filter((block) => block.connector === "welcome")[0]
                  .contents.filter((content) => content.code === language)[0]
                  .button_text
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBlock;
