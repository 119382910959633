import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type InitialState = {
  development: boolean;

  languageDropdown: boolean;
  navigationDrawer: boolean;

  activeNavigationIndex: number;
  trigger: number;

  blocks: block[];
  details: detail[];
  languages: language[];
  navigations: navigation[];
  viewBlock: block | undefined;
};

type block = {
  id: number;
  navigation_id: number;
  connector: string;
  image: string;
  image2: string;
  image3: string;
  contents: content[];
};

type content = {
  id: number;
  language_id: number;
  block_id: number;
  code: string;
  indicator: string;
  title: string;
  sub_title: string;
  button_label: string;
  button_label_2: string;
  button_link: string;
  button_link_2: string;
  button_text: string;
  button_text_2: string;
  description: string;
  description2: string;
  description3: string;
  description4: string;
  description5: string;
};

type detail = {
  banner: string;
  button_link: string;
  button_text: string;
  code: string;
  description: string;
  id: number;
  language_id: number;
  link: string;
  navigation_id: number;
  text: string;
  title: string;
};

type language = {
  id: number;
  code: string;
  name: string;
};

type navigation = {
  id: number;
  language_id: number;
  navigation_id: number;
  indicator: string;
  code: string;
  title: string;
  text: string;
  description: string;
  link: string;
  button_text: string;
  button_link: string;
};

const initialState: InitialState = {
  development: false,

  languageDropdown: false,
  navigationDrawer: false,

  activeNavigationIndex: 0,
  trigger: 0,

  blocks: [],
  details: [],
  languages: [],
  navigations: [],
  viewBlock: undefined,
};

const pageSlice = createSlice({
  name: "pageSlice",
  initialState,
  reducers: {
    updateLanguageDropdownState: (
      state,
      action: PayloadAction<{
        value: boolean;
      }>
    ) => {
      state.languageDropdown = action.payload.value;
    },
    updateNavigationDrawerState: (
      state,
      action: PayloadAction<{
        value: boolean;
      }>
    ) => {
      state.navigationDrawer = action.payload.value;
    },

    updateActiveNavigationIndexState: (
      state,
      action: PayloadAction<{
        value: number;
      }>
    ) => {
      state.activeNavigationIndex = action.payload.value;
    },

    updateTriggerState: (
      state,
      action: PayloadAction<{
        value: number;
      }>
    ) => {
      state.trigger = action.payload.value;
    },

    updateBlocksState: (
      state,
      action: PayloadAction<{
        value: block[];
      }>
    ) => {
      state.blocks = action.payload.value;
    },
    updateDetailsState: (
      state,
      action: PayloadAction<{
        value: detail[];
      }>
    ) => {
      state.details = action.payload.value;
    },
    updateLanguagesState: (
      state,
      action: PayloadAction<{
        value: language[];
      }>
    ) => {
      state.languages = action.payload.value;
    },
    updateNavigationsState: (
      state,
      action: PayloadAction<{
        value: navigation[];
      }>
    ) => {
      state.navigations = action.payload.value;
    },
    updateViewBlockState: (
      state,
      action: PayloadAction<{
        value: block;
      }>
    ) => {
      state.viewBlock = action.payload.value;
    },
  },
});

const pageReducer = pageSlice.reducer;

export const {
  updateLanguageDropdownState,
  updateNavigationDrawerState,

  updateActiveNavigationIndexState,
  updateTriggerState,

  updateBlocksState,
  updateDetailsState,
  updateLanguagesState,
  updateNavigationsState,
  updateViewBlockState,
} = pageSlice.actions;
export default pageReducer;
