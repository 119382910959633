import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import Logo1 from "../../assets/logo1.png";
import art from "../../assets/ART.jpeg";
import neufit from "../../assets/Neufit.png";
import {
  updateActiveNavigationIndexState,
  updateLanguageDropdownState,
  updateNavigationDrawerState,
  updateTriggerState,
} from "../slice";
import { useDispatch } from "react-redux";
import { faBars, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IndexNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language: initialLanguage, link, title } = useParams();
  const [language, setLanguage] = useState(initialLanguage);

  const languageDropdown = useAppSelector(
    (state) => state.page.languageDropdown
  );
  const navigationDrawer = useAppSelector(
    (state) => state.page.navigationDrawer
  );

  const activeNavigationIndex = useAppSelector(
    (state) => state.page.activeNavigationIndex
  );

  const languages = useAppSelector((state) => state.page.languages);
  const navigations = useAppSelector((state) => state.page.navigations);

  return (
    <div className="index-navigation">
      <div className="index-navigation-container">
        <img
          className="logo"
          src={Logo1}
          alt="uemura chiropractic icon"
          onClick={() => {
            navigate(`/${language}`);
            dispatch(
              updateActiveNavigationIndexState({
                value: 0,
              })
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
        <div className="links-container">
          {navigations
            .filter((navigation) => navigation.code === language)
            .map((navigation, index) => (
              <div
                className="link"
                key={index}
                onClick={() => {
                  navigate(`/${language}/${navigation.link}`);
                  dispatch(
                    updateActiveNavigationIndexState({
                      value: index,
                    })
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                {navigation.text}
              </div>
            ))}
        </div>
        <div className="extras-container">
          <img
            src={art}
            alt="art logo"
            onClick={() => {
              navigate(
                `/${language}/${
                  navigations
                    .filter((navigation) => navigation.code === language)
                    .filter(
                      (navigation) => navigation.indicator === "services"
                    )[0].link
                }/#art`
              );
              dispatch(
                updateTriggerState({
                  value: Math.random(),
                })
              );
              dispatch(
                updateActiveNavigationIndexState({
                  value: navigations
                    .filter((navigation) => navigation.code === language)
                    .findIndex(
                      (navigation) => navigation.indicator === "services"
                    ),
                })
              );
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
          <img
            src={neufit}
            alt="neufit logo"
            onClick={() => {
              navigate(
                `/${language}/${
                  navigations
                    .filter((navigation) => navigation.code === language)
                    .filter(
                      (navigation) => navigation.indicator === "services"
                    )[0].link
                }/#neufit`
              );
              dispatch(
                updateTriggerState({
                  value: Math.random(),
                })
              );
              dispatch(
                updateActiveNavigationIndexState({
                  value: navigations
                    .filter((navigation) => navigation.code === language)
                    .findIndex(
                      (navigation) => navigation.indicator === "services"
                    ),
                })
              );
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        </div>
        <div className="dropdown-container">
          <button
            type="button"
            onClick={() =>
              dispatch(
                updateLanguageDropdownState({ value: !languageDropdown })
              )
            }
          >
            {language}
          </button>
          <div
            className={`dropdown-links-container ${
              !languageDropdown ? "hidden" : ""
            }`}
          >
            <ul>
              {languages.map((lang, index) => (
                <a
                  key={index}
                  onClick={() => {
                    navigate(
                      `/${lang.code}${
                        link
                          ? `/${
                              navigations.filter(
                                (navigation) => navigation.code === lang.code
                              )[activeNavigationIndex].link
                            }`
                          : ""
                      }`
                    );
                    setLanguage(lang.code);
                    dispatch(
                      updateLanguageDropdownState({
                        value: !languageDropdown,
                      })
                    );
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <li
                    className={
                      language === lang.code ? "bg-stone-800 text-white" : ""
                    }
                  >
                    {lang.name}
                  </li>
                </a>
              ))}
            </ul>
          </div>
        </div>
        <FontAwesomeIcon
          className="navigation-drawer-toggle-icon"
          icon={faBars}
          onClick={() =>
            dispatch(updateNavigationDrawerState({ value: !navigationDrawer }))
          }
        />
      </div>
      <div
        className={`navigation-drawer ${
          !navigationDrawer ? "-translate-x-full" : ""
        }`}
      >
        <div className="drawer-actions-container">
          <FontAwesomeIcon
            className="drawer-close-icon"
            icon={faCircleXmark}
            onClick={() =>
              dispatch(
                updateNavigationDrawerState({ value: !navigationDrawer })
              )
            }
          />
        </div>
        <div className="drawer-links-container">
          {navigations
            .filter((navigation) => navigation.code === language)
            .map((navigation, index) => (
              <a
                key={index}
                onClick={() => {
                  navigate(`/${language}/${navigation.link}`);
                  dispatch(
                    updateActiveNavigationIndexState({
                      value: index,
                    })
                  );
                  dispatch(
                    updateNavigationDrawerState({
                      value: !navigationDrawer,
                    })
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <div>{navigation.text}</div>
              </a>
            ))}
        </div>
        <div className="extras-containersss">
          <img
            src={art}
            alt="art logo"
            onClick={() => {
              navigate(
                `/${language}/${
                  navigations
                    .filter((navigation) => navigation.code === language)
                    .filter(
                      (navigation) => navigation.indicator === "services"
                    )[0].link
                }/#art`
              );
              dispatch(
                updateTriggerState({
                  value: Math.random(),
                })
              );
              dispatch(
                updateActiveNavigationIndexState({
                  value: navigations
                    .filter((navigation) => navigation.code === language)
                    .findIndex(
                      (navigation) => navigation.indicator === "services"
                    ),
                })
              );
              window.scrollTo({ top: 0, behavior: "smooth" });
              dispatch(
                updateNavigationDrawerState({
                  value: !navigationDrawer,
                })
              );
            }}
          />
          <img
            src={neufit}
            alt="neufit logo"
            onClick={() => {
              navigate(
                `/${language}/${
                  navigations
                    .filter((navigation) => navigation.code === language)
                    .filter(
                      (navigation) => navigation.indicator === "services"
                    )[0].link
                }/#neufit`
              );
              dispatch(
                updateTriggerState({
                  value: Math.random(),
                })
              );
              dispatch(
                updateActiveNavigationIndexState({
                  value: navigations
                    .filter((navigation) => navigation.code === language)
                    .findIndex(
                      (navigation) => navigation.indicator === "services"
                    ),
                })
              );
              window.scrollTo({ top: 0, behavior: "smooth" });
              dispatch(
                updateNavigationDrawerState({
                  value: !navigationDrawer,
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default IndexNavigation;
