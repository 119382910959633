import React from "react";
import Logo from "../../../../assets/logo1.png";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import {
  LoginFormState,
  updateLoginFormProcessingState,
  updateLoginFormState,
} from "../slice";
import { useDispatch } from "react-redux";
import api from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const AdminLoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  let formProcessing = useAppSelector((state) => state.login.formProcessing);
  const form: LoginFormState = useAppSelector((state) => state.login.form);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    dispatch(updateLoginFormState({ name, value }));
  };

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(updateLoginFormProcessingState({ value: true }));

    try {
      const response = await api.post("/login.php", {
        username: form.username,
        password: form.password,
      });

      const res = response.data;

      if (res.status == "invalid account") {
        dispatch(updateLoginFormProcessingState({ value: false }));
        return toast.error("Invalid credentials.");
      }

      navigate("home");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(updateLoginFormProcessingState({ value: false }));
        return toast.error("Error occured.");
      }

      console.log(error);
    }
  };

  return (
    <form className="admin-login-form" onSubmit={login}>
      <img src={Logo} alt="uemura" />
      <div className="inputs-container">
        <div>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            required
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="buttons-container">
        <button disabled={formProcessing} type="submit">
          {formProcessing ? "Processing..." : "Log in"}
        </button>
      </div>
    </form>
  );
};

export default AdminLoginForm;
