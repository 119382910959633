import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import { updateActiveNavigationIndexState } from "../slice";

const ServicesBannerBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const currentURL = window.location.href;

  const develpment = useAppSelector((state) => state.page.development);
  const { language, link, title } = useParams();

  const activeNavigationIndex = useAppSelector(
    (state) => state.page.activeNavigationIndex
  );

  const trigger = useAppSelector((state) => state.page.trigger);

  const blocks = useAppSelector((state) => state.page.blocks);
  const details = useAppSelector((state) => state.page.details);
  const navigations = useAppSelector((state) => state.page.navigations);

  useEffect(() => {
    let id: any = [];

    id = currentURL.split("#");

    if (id.length > 1) {
      id = id.pop();
    }

    setTimeout(() => {
      try {
        const element = document.querySelector(`#${id}`);
        if (element) {
          const pxValue =
            7 * parseFloat(getComputedStyle(document.documentElement).fontSize);
          const yPosition =
            element.getBoundingClientRect().top + window.scrollY - pxValue;
          window.scrollTo({ top: yPosition, behavior: "smooth" });
        }
      } catch {}
    }, 100);
  }, [trigger]);

  return (
    <div>
      <div
        className="w-full h-60 lg:h-96"
        style={{
          backgroundImage: develpment
            ? "#"
            : `url(https://api.uemurachiropractic.com/uploads/${
                details.filter((detail) => detail.code === language)[
                  activeNavigationIndex
                ].banner
              })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div className="flex flex-col py-8">
        <div
          className="pb-4 font-bold self-center text-2xl border-b-8 text-center lg:text-5xl"
          style={{ color: "#90C6B1", borderColor: "#90C6B1" }}
          dangerouslySetInnerHTML={{
            __html: details.filter((detail) => detail.code === language)[
              activeNavigationIndex
            ].title,
          }}
        ></div>
        <div className="flex flex-col gap-16 w-full max-w-screen-xl m-auto p-8">
          {blocks
            .filter(
              (block) =>
                block.navigation_id ===
                navigations
                  .filter((navigation) => navigation.code === language)
                  .filter(
                    (navigation) => navigation.indicator === "services"
                  )[0].navigation_id
            )
            .filter((block) => block.connector === (title ? title : ""))
            .map((block, index) => {
              if (index % 2 !== 0) {
                return (
                  <div
                    className="flex flex-col gap-4 lg:flex-row-reverse lg:items-center"
                    id={
                      block.contents.filter(
                        (content: any) => content.code === language
                      )[0].indicator
                    }
                  >
                    <div
                      className="h-80 rounded-3xl lg:flex-1"
                      style={{
                        backgroundImage: develpment
                          ? "#"
                          : `url(https://api.uemurachiropractic.com/uploads/${block.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                    <div className="flex flex-col gap-4 justify-end flex-1">
                      <div
                        className="font-bold text-2xl text-center"
                        dangerouslySetInnerHTML={{
                          __html: block.contents.filter(
                            (content: any) => content.code === language
                          )[0].title,
                        }}
                      />
                      <div
                        className="text-lg text-center"
                        dangerouslySetInnerHTML={{
                          __html: block.contents.filter(
                            (content: any) => content.code === language
                          )[0].description,
                        }}
                      />
                      {block.contents.filter(
                        (content: any) => content.code === language
                      )[0].button_link === "" ? null : (
                        <div className="flex flex-col gap-4 items-center">
                          <span
                            className="text-lg"
                            dangerouslySetInnerHTML={{
                              __html: block.contents.filter(
                                (content: any) => content.code === language
                              )[0].button_label,
                            }}
                          />
                          <a
                            href={
                              block.contents.filter(
                                (content: any) => content.code === language
                              )[0].button_link
                            }
                          >
                            <button className="btn rounded-full outline-none border-0 text-white bg-purple-900 hover:bg-purple-800 underline">
                              {
                                block.contents.filter(
                                  (content: any) => content.code === language
                                )[0].button_text
                              }
                            </button>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="flex flex-col gap-4 lg:flex-row lg:items-center"
                    id={
                      block.contents.filter(
                        (content: any) => content.code === language
                      )[0].indicator
                    }
                  >
                    <div
                      className="h-80 rounded-3xl lg:flex-1"
                      style={{
                        backgroundImage: develpment
                          ? "#"
                          : `url(https://api.uemurachiropractic.com/uploads/${block.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                    <div className="flex flex-col gap-4 justify-end flex-1">
                      <div
                        className="font-bold text-2xl text-center"
                        dangerouslySetInnerHTML={{
                          __html: block.contents.filter(
                            (content: any) => content.code === language
                          )[0].title,
                        }}
                      />
                      <div
                        className="text-lg text-center"
                        dangerouslySetInnerHTML={{
                          __html: block.contents.filter(
                            (content: any) => content.code === language
                          )[0].description,
                        }}
                      />
                      {block.contents.filter(
                        (content: any) => content.code === language
                      )[0].button_link === "" ? null : (
                        <div className="flex flex-col gap-4 items-center">
                          <span
                            className="text-lg"
                            dangerouslySetInnerHTML={{
                              __html: block.contents.filter(
                                (content: any) => content.code === language
                              )[0].button_label,
                            }}
                          />
                          <a
                            href={
                              block.contents.filter(
                                (content: any) => content.code === language
                              )[0].button_link
                            }
                          >
                            <button className="btn rounded-full outline-none border-0 text-white bg-purple-900 hover:bg-purple-800 underline">
                              {
                                block.contents.filter(
                                  (content: any) => content.code === language
                                )[0].button_text
                              }
                            </button>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          {blocks
            .filter(
              (block) =>
                block.navigation_id ===
                navigations
                  .filter((navigation) => navigation.code === language)
                  .filter(
                    (navigation) => navigation.indicator === "services"
                  )[0].navigation_id
            )
            .filter((block) => block.connector === "button")
            .map((block: any, index) => (
              <a
                className="self-center"
                onClick={() => {
                  navigate(
                    `/${language}/${
                      navigations
                        .filter((navigation) => navigation.code === language)
                        .filter(
                          (navigation) => navigation.indicator === "contact-us"
                        )[0].link
                    }${title ? `/${title}` : ""}`
                  );
                  dispatch(
                    updateActiveNavigationIndexState({
                      value: navigations
                        .filter((navigation) => navigation.code === language)
                        .findIndex(
                          (navigation) => navigation.indicator === "contact-us"
                        ),
                    })
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <button
                  className="btn rounded-full outline-none border-0 text-white"
                  style={{ backgroundColor: "#90C6B1" }}
                >
                  {
                    block.contents.filter(
                      (content: any) => content.code === language
                    )[0].button_text
                  }
                </button>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesBannerBlock;
