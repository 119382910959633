import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import { updateActiveNavigationIndexState } from "../slice";

const DoctorsBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language, link, title } = useParams();

  const develpment = useAppSelector((state) => state.page.development);
  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  const [doctorsIndex, setDoctorsIndex] = useState(0);
  const [doctorsMaxIndex, setDoctorsMaxIndex] = useState(0);

  const doctorsSlider = () => {
    console.log([doctorsIndex, doctorsMaxIndex - 1]);
    if (doctorsIndex === doctorsMaxIndex - 1) setDoctorsIndex(0);
    else setDoctorsIndex(doctorsIndex + 1);
  };

  useEffect(() => {
    setDoctorsMaxIndex(
      blocks.filter((block) => block.connector === "doctors").length
    );
    console.log(blocks.filter((block) => block.connector === "doctors").length);
  }, [blocks]);

  useEffect(() => {
    if (doctorsMaxIndex <= 0) return;
    const timer = setTimeout(() => {
      doctorsSlider();
    }, 15000);

    return () => clearTimeout(timer);
  }, [doctorsMaxIndex]);

  useEffect(() => {
    if (doctorsMaxIndex <= 0) return;
    const timer = setTimeout(() => {
      doctorsSlider();
    }, 15000);

    return () => clearTimeout(timer);
  }, [doctorsIndex]);

  return (
    <>
      <div className="doctors-block">
        <div
          className="image"
          style={{
            backgroundImage: develpment
              ? "#"
              : `url(https://api.uemurachiropractic.com/uploads/${
                  blocks.filter((block) => block.connector === "doctors")[
                    doctorsIndex
                  ].image
                })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html:
              blocks
                .filter((block) => block.connector === "doctors")
                [doctorsIndex].contents.filter(
                  (content) => content.code === language
                )[0].title +
              " | " +
              doctorsMaxIndex,
          }}
        />
        <div
          className="sub-title"
          dangerouslySetInnerHTML={{
            __html: blocks
              .filter((block) => block.connector === "doctors")
              [doctorsIndex].contents.filter(
                (content) => content.code === language
              )[0].sub_title,
          }}
        />
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: blocks
              .filter((block) => block.connector === "doctors")
              [doctorsIndex].contents.filter(
                (content) => content.code === language
              )[0].description,
          }}
        />
        <button
          style={{ backgroundColor: "#90C6B1" }}
          onClick={() => {
            navigate(
              `/${language}/${
                navigations
                  .filter((navigation) => navigation.code === language)
                  .filter(
                    (navigation) => navigation.indicator === "contact-us"
                  )[0].link
              }${title ? `/${title}` : ""}`
            );
            dispatch(
              updateActiveNavigationIndexState({
                value: navigations
                  .filter((navigation) => navigation.code === language)
                  .findIndex(
                    (navigation) => navigation.indicator === "contact-us"
                  ),
              })
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          {
            blocks
              .filter((block) => block.connector === "doctors")
              [doctorsIndex].contents.filter(
                (content) => content.code === language
              )[0].button_text
          }
        </button>
      </div>

      {/* ------------------------------------------ */}

      <div
        className="doctors-block-large"
        style={{
          backgroundImage: develpment
            ? "#"
            : `url(https://api.uemurachiropractic.com/uploads/${
                blocks.filter((block) => block.connector === "doctors")[
                  doctorsIndex
                ].image
              })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="fader"
          style={{
            background:
              "linear-gradient(to left, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.2) 70%)",
          }}
        >
          <div className="portions-container">
            <div className="left-portion"></div>
            <div className="right-portion">
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter((block) => block.connector === "doctors")
                    [doctorsIndex].contents.filter(
                      (content) => content.code === language
                    )[0].title,
                }}
              />
              <div
                className="sub-title"
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter((block) => block.connector === "doctors")
                    [doctorsIndex].contents.filter(
                      (content) => content.code === language
                    )[0].sub_title,
                }}
              />
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter((block) => block.connector === "doctors")
                    [doctorsIndex].contents.filter(
                      (content) => content.code === language
                    )[0].description,
                }}
              />
              <button
                style={{ backgroundColor: "#90C6B1" }}
                onClick={() => {
                  navigate(
                    `/${language}/${
                      navigations
                        .filter((navigation) => navigation.code === language)
                        .filter(
                          (navigation) => navigation.indicator === "contact-us"
                        )[0].link
                    }${title ? `/${title}` : ""}`
                  );
                  dispatch(
                    updateActiveNavigationIndexState({
                      value: navigations
                        .filter((navigation) => navigation.code === language)
                        .findIndex(
                          (navigation) => navigation.indicator === "contact-us"
                        ),
                    })
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                {
                  blocks
                    .filter((block) => block.connector === "doctors")
                    [doctorsIndex].contents.filter(
                      (content) => content.code === language
                    )[0].button_text
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorsBlock;
