import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import { updateActiveNavigationIndexState } from "../slice";
import { useDispatch } from "react-redux";

const ServicesHomeBlock = () => {
  const { language, title } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const develpment = useAppSelector((state) => state.page.development);
  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  return (
    <div className="services-home-block">
      <div className="content-container">
        <div
          className="title"
          style={{ color: "#90C6B1" }}
          dangerouslySetInnerHTML={{
            __html: blocks
              .filter((block) => block.connector === "services")[0]
              .contents.filter((content) => content.code === language)[0].title,
          }}
        />
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: blocks
              .filter((block) => block.connector === "services")[0]
              .contents.filter((content) => content.code === language)[0]
              .description,
          }}
        />
        <div className="featured-container">
          {blocks
            .filter(
              (block) =>
                block.navigation_id ===
                navigations
                  .filter((navigation) => navigation.code === language)
                  .filter(
                    (navigation) => navigation.indicator === "services"
                  )[0].navigation_id
            )
            .filter((block) => block.connector === "featured")
            .map((block, index) => (
              <div key={index} className="featured-service">
                <div className="left-portion">
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#90C6B1",
                      backgroundImage: develpment
                        ? "#"
                        : `url(https://api.uemurachiropractic.com/uploads/${block.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                </div>
                <div className="right-portion">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: block.contents.filter(
                        (content) => content.code === language
                      )[0].title,
                    }}
                  />
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: block.contents.filter(
                        (content) => content.code === language
                      )[0].description,
                    }}
                  />
                  {block.contents.filter(
                    (content) => content.code === language
                  )[0].button_link === "" ? null : (
                    <a
                      className="cursor-pointer"
                      style={{ color: "#90C6B1" }}
                      onClick={() => {
                        navigate(
                          `/${language}/${
                            navigations
                              .filter(
                                (navigation) => navigation.code === language
                              )
                              .filter(
                                (navigation) =>
                                  navigation.indicator === "services"
                              )[0].link
                          }${index == 1 ? `/other` : ""}`
                        );
                        dispatch(
                          updateActiveNavigationIndexState({
                            value: navigations
                              .filter(
                                (navigation) => navigation.code === language
                              )
                              .findIndex(
                                (navigation) =>
                                  navigation.indicator === "services"
                              ),
                          })
                        );
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      {
                        block.contents.filter(
                          (content) => content.code === language
                        )[0].button_text
                      }
                    </a>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesHomeBlock;
