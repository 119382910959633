import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import { updateViewBlockState } from "../slice";

const ConditionBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language, link, title } = useParams();

  const develpment = useAppSelector((state) => state.page.development);
  const blocks = useAppSelector((state) => state.page.blocks);
  const details = useAppSelector((state) => state.page.details);
  const navigations = useAppSelector((state) => state.page.navigations);

  return (
    <div className="text-gray-900">
      <div
        className="lg:h-screen h-80 bg-gray-700"
        style={{
          backgroundImage: develpment
            ? "#"
            : `url(https://api.uemurachiropractic.com/uploads/${
                blocks.filter((block) => block.connector === "block-1").length >
                0
                  ? (
                      blocks
                        .filter(
                          (block) =>
                            block.navigation_id ===
                            navigations.filter(
                              (navigation) => navigation.link === link
                            )[0].navigation_id
                        )
                        .filter(
                          (block) => block.connector === "featured"
                        )[0] as any
                    ).image
                  : ""
              })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="flex flex-col h-full text-white"
          style={{
            background:
              "linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.7) 90%)",
          }}
        >
          <div className="w-full max-w-screen-xl m-auto mb-0 p-8">
            {blocks.filter((block) => block.connector === "block-1").length >
            0 ? (
              <>
                <div
                  className="italic lg:text-lg"
                  style={{ color: "#90C6B1" }}
                  dangerouslySetInnerHTML={{
                    __html: (
                      blocks
                        .filter(
                          (block) =>
                            block.navigation_id ===
                            navigations.filter(
                              (navigation) => navigation.link === link
                            )[0].navigation_id
                        )
                        .filter(
                          (block) => block.connector === "featured"
                        )[0] as any
                    ).contents.filter(
                      (content: any) => content.code === language
                    )[0].sub_title,
                  }}
                />
                <div
                  className="font-bold lg:text-4xl"
                  dangerouslySetInnerHTML={{
                    __html: (
                      blocks
                        .filter(
                          (block) =>
                            block.navigation_id ===
                            navigations.filter(
                              (navigation) => navigation.link === link
                            )[0].navigation_id
                        )
                        .filter(
                          (block) => block.connector === "featured"
                        )[0] as any
                    ).contents.filter(
                      (content: any) => content.code === language
                    )[0].title,
                  }}
                />
                <div
                  className="mt-8 hidden lg:block text-gray-300"
                  dangerouslySetInnerHTML={{
                    __html: (
                      blocks
                        .filter(
                          (block) =>
                            block.navigation_id ===
                            navigations.filter(
                              (navigation) => navigation.link === link
                            )[0].navigation_id
                        )
                        .filter(
                          (block) => block.connector === "featured"
                        )[0] as any
                    ).contents.filter(
                      (content: any) => content.code === language
                    )[0].description,
                  }}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-full max-w-screen-xl m-auto mb-0 p-8">
        {details.length > 0 ? (
          <div className="flex items-center justify-between">
            <div className="font-bold text-2xl">
              {
                (
                  details.filter(
                    (detail) => (detail as any).code === language
                  )[0] as any
                ).title
              }
            </div>
          </div>
        ) : null}
        {blocks.filter((block) => block.connector === "").length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 pt-16 sm:grid-cols-3">
            {blocks
              .filter(
                (block) =>
                  block.navigation_id ===
                  navigations.filter(
                    (navigation) => navigation.link === link
                  )[0].navigation_id
              )
              .filter(
                (block) => block.connector === "" || block.connector === "other"
              )
              .map((block: any) => (
                <a
                  onClick={() => {
                    updateViewBlockState(block);
                    navigate(
                      `/${language}/${link}/${encodeURIComponent(
                        block.contents.filter(
                          (content: any) => content.code === language
                        )[0].title
                      )}`
                    );
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <div
                    className="h-56 bg-gray-700 rounded-3xl"
                    style={{
                      backgroundColor: "#90C6B1",
                      backgroundImage: develpment
                        ? "#"
                        : `url(https://api.uemurachiropractic.com/uploads/${block.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <div
                    className="mt-4 font-bold text-xl"
                    dangerouslySetInnerHTML={{
                      __html: block.contents.filter(
                        (content: any) => content.code === language
                      )[0].title,
                    }}
                  />
                  <div
                    className="mt-2 text-sm"
                    dangerouslySetInnerHTML={{
                      __html: block.contents.filter(
                        (content: any) => content.code === language
                      )[0].sub_title,
                    }}
                  />
                </a>
              ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ConditionBlock;
