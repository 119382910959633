import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  updateActiveNavigationIndexState,
  updateViewBlockState,
} from "../slice";

const ConditionsHomeBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language, link, title } = useParams();

  const develpment = useAppSelector((state) => state.page.development);

  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  const [conditionScrollDisabled, setConditionScrollDisabled] = useState(false);

  const scrollConditionContainer = (offset: number) => {
    if (conditionScrollDisabled) return;

    const container = document.getElementById("conditions-home-container");
    container!.scrollLeft += (container!.clientWidth + 16) * offset;
    // setConditionScrollDisabled(true);
    // setTimeout(() => {
    //   setConditionScrollDisabled(false);
    // }, 1000);
  };

  return (
    <div className="conditions-home-block">
      <div className="content-container">
        <div
          className="title"
          style={{
            color: "#90C6B1",
          }}
          dangerouslySetInnerHTML={{
            __html: blocks
              .filter((block) => block.connector === "conditions")[0]
              .contents.filter((content) => content.code === language)[0].title,
          }}
        />
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: blocks
              .filter((block) => block.connector === "conditions")[0]
              .contents.filter((content) => content.code === language)[0]
              .description,
          }}
        />
        <div className="items-container">
          <div className="items-wrapper" id="conditions-home-container">
            {blocks
              .filter(
                (block) =>
                  block.navigation_id ===
                  navigations
                    .filter((navigation) => navigation.code === language)
                    .filter(
                      (navigation) => navigation.indicator === "condition"
                    )[0].navigation_id
              )
              .filter((block) => block.connector === "")
              .map((block, index) => (
                <div
                  key={index}
                  className="item"
                  style={{
                    backgroundImage: develpment
                      ? "#"
                      : `url(https://api.uemurachiropractic.com/uploads/${block.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div
                    className="fader"
                    style={{
                      background:
                        "linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.7) 90%)",
                    }}
                  >
                    <div
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: block.contents.filter(
                          (content) => content.code === language
                        )[0].title,
                      }}
                    />
                    <a
                      className="cursor-pointer"
                      onClick={() => {
                        updateViewBlockState(block as any);
                        navigate(
                          `/${language}/${
                            navigations
                              .filter(
                                (navigation) => navigation.code === language
                              )
                              .filter(
                                (navigation) =>
                                  navigation.indicator === "condition"
                              )[0].link
                          }/${encodeURIComponent(
                            block.contents.filter(
                              (content: any) => content.code === language
                            )[0].title
                          )}`
                        );
                        dispatch(
                          updateActiveNavigationIndexState({
                            value: navigations
                              .filter(
                                (navigation) => navigation.code === language
                              )
                              .findIndex(
                                (navigation) =>
                                  navigation.indicator === "condition"
                              ),
                          })
                        );
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      {
                        block.contents.filter(
                          (content: any) => content.code === language
                        )[0].button_label
                      }
                    </a>
                  </div>
                </div>
              ))}
          </div>
          <div className="actions-container">
            <FontAwesomeIcon
              className="action-icon"
              icon={faChevronLeft}
              onClick={() => scrollConditionContainer(-1)}
            />
            <FontAwesomeIcon
              className="action-icon"
              icon={faChevronRight}
              onClick={() => scrollConditionContainer(1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionsHomeBlock;
