import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AdminLanguagesCreate = () => {
  const navigate = useNavigate();
  const [requestProcessing, setRequestProcessing] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    code: "",
    name: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (requestProcessing) {
      return;
    }

    try {
      setRequestProcessing(true);
      const data = new FormData();
      data.append("code", formData.code);
      data.append("name", formData.name);

      await fetch("https://api.uemurachiropractic.com/create-language.php", {
        method: "POST",
        body: data,
      });

      toast.success("Create success.");
      navigate(-1);
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
      setRequestProcessing(false);
    }
  };

  return (
    <div className="h-screen py-12 bg-gray-200">
      <form
        onSubmit={handleOnSubmit}
        className="flex flex-col gap-4 max-w-sm mx-auto p-4 bg-white shadow-lg"
      >
        <h1 className="font-bold text-2xl text-center">New Language</h1>
        <div>
          <label htmlFor="code">Code</label>
          <input
            name="code"
            type="text"
            className="input input-bordered w-full"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="name">Name</label>
          <input
            name="name"
            type="text"
            className="input input-bordered w-full"
            onChange={handleInputChange}
          />
        </div>
        <div className="flex flex-row-reverse gap-4 mt-4">
          <button type="submit" className="btn btn-primary flex-1">
            {requestProcessing ? "Processing..." : "Save"}
          </button>
          <button
            type="button"
            className="btn btn-error flex-1"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminLanguagesCreate;
