import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AdminLoginScreen from "./components/admin/login/AdminLoginScreen";
import PageScreen from "./components/PageScreen";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import AdminBlocks from "./AdminBlocks";
import AdminHome from "./AdminHome";
import AdminLanguages from "./AdminLanguages";
import AdminLanguagesCreate from "./AdminLanguagesCreate";
import AdminNavigations from "./AdminNavigations";
import AdminNavigationsCreateBlock from "./AdminNavigationsCreateBlock";
import ConditionBlock from "./components/widgets/ConditionBlock";
import PrivacyBlock from "./components/widgets/PrivacyBlock";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Outlet />}>
        <Route index element={<Navigate to="/en" replace />} />
        <Route path="admin">
          <Route index element={<AdminLoginScreen />} />
          <Route path="home" element={<AdminHome />} />
          <Route path="languages">
            <Route index element={<AdminLanguages />} />
            <Route path="create" element={<AdminLanguagesCreate />} />
          </Route>
          <Route path="navigations">
            <Route index element={<AdminNavigations />} />
            <Route path="create" element={<AdminLanguagesCreate />} />
          </Route>
          <Route path="navigation">
            <Route path=":indicator">
              <Route index element={<AdminBlocks />} />
              <Route path="create" element={<AdminNavigationsCreateBlock />} />
            </Route>
          </Route>
        </Route>
        <Route path=":language/:link?/:title?" element={<PageScreen />} />
      </Route>
    )
  );

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Provider>
  );
}

export default App;
