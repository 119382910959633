import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/store";

const WhatsNewBlock = () => {
  const { language } = useParams();

  const blocks = useAppSelector((state) => state.page.blocks);

  const [whatsNewIndex, setWhatsNewIndex] = useState(0);
  const [whatsNewMaxIndex, setWhatsNewMaxIndex] = useState(0);

  const develpment = useAppSelector((state) => state.page.development);
  const whatsNewSlider = () => {
    if (whatsNewIndex === whatsNewMaxIndex - 1) setWhatsNewIndex(0);
    else setWhatsNewIndex(whatsNewIndex + 1);
  };

  useEffect(() => {
    setWhatsNewMaxIndex(
      blocks.filter((block) => block.connector === "whats-new").length
    );
  }, [blocks]);

  useEffect(() => {
    if (whatsNewMaxIndex <= 0) return;
    const timer = setTimeout(() => {
      whatsNewSlider();
    }, 15000);

    return () => clearTimeout(timer);
  }, [whatsNewMaxIndex]);

  useEffect(() => {
    if (whatsNewMaxIndex <= 0) return;
    const timer = setTimeout(() => {
      whatsNewSlider();
    }, 15000);

    return () => clearTimeout(timer);
  }, [whatsNewIndex]);

  return (
    <div
      className="whats-new-block"
      style={{
        backgroundColor: "#fff",
      }}
    >
      <div className="portions-container">
        <div className="portions-bg">
          <div className="left-portion">
            <div className="holder">
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter((block) => block.connector === "whats-new")
                    [whatsNewIndex].contents.filter(
                      (content) => content.code === language
                    )[0].title,
                }}
              />
              <div
                className="sub-title"
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter((block) => block.connector === "whats-new")
                    [whatsNewIndex].contents.filter(
                      (content) => content.code === language
                    )[0].sub_title,
                }}
              />
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: blocks
                    .filter((block) => block.connector === "whats-new")
                    [whatsNewIndex].contents.filter(
                      (content) => content.code === language
                    )[0].description,
                }}
              />
            </div>
          </div>
          <div className="right-portion">
            <img
              className="w-full h-full object-cover"
              src={
                develpment
                  ? "#"
                  : `https://api.uemurachiropractic.com/uploads/${
                      blocks.filter((block) => block.connector === "whats-new")[
                        whatsNewIndex
                      ].image
                    }`
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsNewBlock;
