import React, { useEffect, useState } from "react";
import AdminNavigation from "./components/AdminNavigation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AdminLanguages = () => {
  const navigate = useNavigate();
  const [languages, setLanguages] = useState([]);

  const getLanguages = async () => {
    try {
      const response = await fetch(
        "https://api.uemurachiropractic.com/get-languages.php",
        {
          method: "GET",
        }
      );
      setLanguages(JSON.parse(await response.text()));
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <div className="flex">
      <AdminNavigation />
      <div className="flex flex-col gap-4 flex-1 h-screen p-4 bg-gray-200 overflow-auto">
        <h1 className="mr-auto font-bold text-2xl">Languages</h1>
        <hr className="border-2 border-gray-800" />
        {/* <div className="flex gap-2">
          <button
            className="btn btn-primary"
            onClick={() => navigate("create")}
          >
            New Language
          </button>
        </div> */}
        {languages.length === 0 ? (
          <span className="loading loading-dots loading-lg m-auto"></span>
        ) : (
          <table className="table bg-white">
            <thead>
              <tr className="bg-gray-900 text-white">
                <th>Code</th>
                <th>Name</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {languages.map((lang, index) => (
                <tr className="hover:bg-blue-200" key={index}>
                  <td>{(lang as any).code}</td>
                  <td>{(lang as any).name}</td>
                  {/* <td>
                    <span className="cursor-pointer select-none underline">
                      edit
                    </span>{" "}
                    |{" "}
                    <span className="cursor-pointer select-none underline">
                      remove
                    </span>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AdminLanguages;
