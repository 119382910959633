import React, { useEffect } from "react";
import "./style.scss";
import IndexNavigation from "./widgets/IndexNavigation";
import WelcomeBlock from "./widgets/WelcomeBlock";
import { toast } from "react-toastify";
import api from "../services/api";
import {
  updateBlocksState,
  updateDetailsState,
  updateLanguagesState,
  updateNavigationsState,
} from "./slice";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../redux/store";
import { useParams } from "react-router-dom";
import WhatsNewBlock from "./widgets/WhatsNewBlock";
import ServicesHomeBlock from "./widgets/ServicesHomeBlock";
import Info1Block from "./widgets/Info1Block";
import DoctorsBlock from "./widgets/DoctorsBlock";
import ConditionsHomeBlock from "./widgets/ConditionsHomeBlock";
import TestimonialsBlock from "./widgets/TestimonialsBlock";
import MapBlock from "./widgets/MapBlock";
import ContactUsBlock from "./widgets/ContactUsBlock";
import ServicesBannerBlock from "./widgets/ServicesBannerBlock";
import ShopBlock from "./widgets/ShopBlock";
import ConditionBlock from "./widgets/ConditionBlock";
import ConditionViewBlock from "./widgets/ConditionViewBlock";
import PrivacyBlock from "./widgets/PrivacyBlock";
import HippaBlock from "./widgets/HippaBlock";

const PageScreen = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { language, link, title } = useParams();

  const activeNavigationIndex = useAppSelector(
    (state) => state.page.activeNavigationIndex
  );

  const blocks = useAppSelector((state) => state.page.blocks);
  const languages = useAppSelector((state) => state.page.languages);
  const navigations = useAppSelector((state) => state.page.navigations);

  const getBlocks = async () => {
    dispatch(updateBlocksState({ value: [] }));

    try {
      const response = await api.post("/get-all-navigation-blocks.php");
      const res = response.data;

      dispatch(updateBlocksState({ value: res.blocks }));
      dispatch(updateDetailsState({ value: res.details }));
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
    }
  };

  const getLanguages = async () => {
    dispatch(updateLanguagesState({ value: [] }));

    try {
      const response = await api.post("/get-languages.php");
      const res = response.data;

      dispatch(updateLanguagesState({ value: res }));
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
    }
  };

  const getNavigations = async () => {
    dispatch(updateLanguagesState({ value: [] }));

    try {
      const response = await api.post("/get-index-navigations.php");
      const res = response.data;

      dispatch(updateNavigationsState({ value: res }));
    } catch (error) {
      toast.error("Request failed!");
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    getBlocks();
    getLanguages();
    getNavigations();
  }, []);

  return (
    <div className="w-screen min-h-screen">
      {languages.length > 0 ? (
        <>
          {navigations.length > 0 ? <IndexNavigation /> : null}
          <div className="h-full pt-20">
            {link === "privacy-policy" ? (
              <>
                {blocks.length > 0 ? (
                  <>
                    <PrivacyBlock />
                    <ContactUsBlock />
                  </>
                ) : null}
              </>
            ) : null}
            {link === "hippa-policy" ? (
              <>
                {blocks.length > 0 ? (
                  <>
                    <HippaBlock />
                    <ContactUsBlock />
                  </>
                ) : null}
              </>
            ) : null}
            {blocks.length > 0 && activeNavigationIndex !== -1 ? (
              navigations.filter((navigation) => navigation.code === language)[
                activeNavigationIndex
              ].indicator === "home" ? (
                <>
                  <WelcomeBlock />
                  <WhatsNewBlock />
                  <ServicesHomeBlock />
                  <Info1Block />
                  <DoctorsBlock />
                  <ConditionsHomeBlock />
                  <TestimonialsBlock />
                  <MapBlock />
                  <ContactUsBlock />
                </>
              ) : null
            ) : null}
            {blocks.length > 0 && activeNavigationIndex !== -1 ? (
              navigations.filter((navigation) => navigation.code === language)[
                activeNavigationIndex
              ].indicator === "services" ? (
                <>
                  <ServicesBannerBlock />
                  <ContactUsBlock />
                </>
              ) : null
            ) : null}
            {blocks.length > 0 && activeNavigationIndex !== -1 ? (
              navigations.filter((navigation) => navigation.code === language)[
                activeNavigationIndex
              ].indicator === "condition" ? (
                title ? (
                  <>
                    <ConditionViewBlock />
                    <MapBlock />
                    <ContactUsBlock />
                  </>
                ) : (
                  <>
                    <ConditionBlock />
                    <ContactUsBlock />
                  </>
                )
              ) : null
            ) : null}
            {blocks.length > 0 && activeNavigationIndex !== -1 ? (
              navigations.filter((navigation) => navigation.code === language)[
                activeNavigationIndex
              ].indicator === "shop" ? (
                <>
                  <ShopBlock />
                  <ContactUsBlock />
                </>
              ) : null
            ) : null}
            {blocks.length > 0 && activeNavigationIndex !== -1 ? (
              navigations.filter((navigation) => navigation.code === language)[
                activeNavigationIndex
              ].indicator === "contact-us" ? (
                <div className="bg-stone-800 h-screen">
                  <ContactUsBlock />
                </div>
              ) : null
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PageScreen;
