import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";

const HippaBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const currentURL = window.location.href;

  const develpment = useAppSelector((state) => state.page.development);
  const { language, link, title } = useParams();

  const activeNavigationIndex = useAppSelector(
    (state) => state.page.activeNavigationIndex
  );

  const blocks = useAppSelector((state) => state.page.blocks);
  const details = useAppSelector((state) => state.page.details);
  const navigations = useAppSelector((state) => state.page.navigations);

  useEffect(() => {
    let id: any = [];

    id = currentURL.split("#");

    if (id.length > 1) {
      id = id.pop();
    }

    setTimeout(() => {
      try {
        const element = document.querySelector(`#${id}`);
        if (element) {
          const pxValue =
            7 * parseFloat(getComputedStyle(document.documentElement).fontSize);
          const yPosition =
            element.getBoundingClientRect().top + window.scrollY - pxValue;
          window.scrollTo({ top: yPosition, behavior: "smooth" });
        }
      } catch {}
    }, 100);
  }, [activeNavigationIndex]);

  return (
    <div className="w-full max-w-screen-xl m-auto p-8">
      {blocks.length > 0 ? (
        <div className="flex flex-col py-8">
          <div
            className="pb-4 font-bold self-center text-2xl border-b-8 text-center lg:text-5xl"
            style={{ borderColor: "#90C6B1" }}
            dangerouslySetInnerHTML={{
              __html: blocks
                .filter((block) => block.navigation_id === 1)
                .filter((block) => block.connector === "hippa")[0]
                .contents.filter((content: any) => content.code === language)[0]
                .title,
            }}
          ></div>
          <div
            className="text-center my-4"
            dangerouslySetInnerHTML={{
              __html: blocks
                .filter((block) => block.navigation_id === 1)
                .filter((block) => block.connector === "hippa")[0]
                .contents.filter((content: any) => content.code === language)[0]
                .sub_title,
            }}
          />
          <div
            className="my-4 text-justify"
            dangerouslySetInnerHTML={{
              __html: blocks
                .filter((block) => block.navigation_id === 1)
                .filter((block) => block.connector === "hippa")[0]
                .contents.filter((content: any) => content.code === language)[0]
                .description,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default HippaBlock;
