import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";

const ShopBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language, link, title } = useParams();

  const develpment = useAppSelector((state) => state.page.development);
  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  return (
    <div className="text-gray-900 bg-stone-200">
      <div
        className="h-72 lg:h-screen"
        style={{
          backgroundImage: `url(https://api.uemurachiropractic.com/uploads/${
            blocks.filter((block) => block.connector === "header")[0].image
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="flex flex-col h-full"
          style={{
            background:
              "linear-gradient(to bottom, transparent 60%, rgba(255, 255, 255, 0.7) 90%)",
          }}
        >
          <div className="w-full max-w-screen-xl m-auto mb-0 p-8">
            {blocks.filter((block) => block.connector === "header").length >
            0 ? (
              <>
                <div
                  className="text-2xl lg:text-4xl"
                  dangerouslySetInnerHTML={{
                    __html: (
                      blocks.filter(
                        (block) => block.connector === "header"
                      )[0] as any
                    ).contents.filter(
                      (content: any) => content.code === language
                    )[0].title,
                  }}
                />
                <div
                  className="text-xl lg:text-4xl font-bold"
                  dangerouslySetInnerHTML={{
                    __html: (
                      blocks.filter(
                        (block) => block.connector === "header"
                      )[0] as any
                    ).contents.filter(
                      (content: any) => content.code === language
                    )[0].sub_title,
                  }}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-full max-w-screen-xl m-auto mb-0 p-8">
        {blocks.length > 0 ? (
          <>
            {blocks
              .filter((block) => block.connector === "block-1")
              .map((block: any) => (
                <div>
                  <div className="flex flex-col-reverse gap-8 lg:flex-row">
                    <div className="flex-1">
                      <div
                        className="text-center lg:text-left"
                        dangerouslySetInnerHTML={{
                          __html: block.contents.filter(
                            (content: any) => content.code === language
                          )[0].description,
                        }}
                      />
                      <a
                        className="block m-auto lg:m-0"
                        href={
                          block.contents.filter(
                            (content: any) => content.code === language
                          )[0].button_link
                        }
                      >
                        <button className="btn rounded-full outline-none border-0 mt-8 min-w-60 bg-lime-500 text-white underline hover:bg-lime-500">
                          {
                            block.contents.filter(
                              (content: any) => content.code === language
                            )[0].button_text
                          }
                        </button>
                      </a>
                    </div>
                    <div className="w-full lg:min-w-80 lg:w-auto">
                      <img
                        className="w-60 m-auto"
                        src={
                          develpment
                            ? "#"
                            : `https://api.uemurachiropractic.com/uploads/${block.image}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ))}
            <div className="mt-16 flex flex-col gap-8 bg-white rounded-3xl p-8 lg:flex-row">
              {blocks
                .filter((block) => block.connector === "block-2-content")
                .map((block: any) => (
                  <div className="flex-1">
                    <img
                      className="w-24 m-auto"
                      src={
                        develpment
                          ? "#"
                          : `https://api.uemurachiropractic.com/uploads/${block.image}`
                      }
                      alt=""
                    />
                    <div
                      className="font-bold text-2xl text-center"
                      dangerouslySetInnerHTML={{
                        __html: block.contents.filter(
                          (content: any) => content.code === language
                        )[0].title,
                      }}
                    />
                    <div
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: block.contents.filter(
                          (content: any) => content.code === language
                        )[0].description,
                      }}
                    />
                  </div>
                ))}
            </div>
            {blocks
              .filter((block) => block.connector === "block-3")
              .map((block: any) => (
                <div className="mt-16">
                  <div
                    className="text-center whitespace-pre-line"
                    dangerouslySetInnerHTML={{
                      __html: block.contents.filter(
                        (content: any) => content.code === language
                      )[0].description,
                    }}
                  />
                  <img
                    className="w-80 m-auto mt-8"
                    src={
                      develpment
                        ? "#"
                        : `https://api.uemurachiropractic.com/uploads/${block.image}`
                    }
                    alt=""
                  />
                </div>
              ))}
            {blocks
              .filter((block) => block.connector === "block-4")
              .map((block: any) => (
                <div className="max-w-2xl m-auto mt-16">
                  <div
                    className="text-center font-bold text-xl italic"
                    dangerouslySetInnerHTML={{
                      __html: block.contents.filter(
                        (content: any) => content.code === language
                      )[0].description,
                    }}
                  />
                </div>
              ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ShopBlock;
