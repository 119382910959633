import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../redux/store";

const MapBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { language, link, title } = useParams();

  const develpment = useAppSelector((state) => state.page.development);
  const blocks = useAppSelector((state) => state.page.blocks);
  const navigations = useAppSelector((state) => state.page.navigations);

  return (
    <div className="bg-white">
      <div
        className="p-8 font-bold text-2xl text-center underline"
        dangerouslySetInnerHTML={{
          __html: blocks
            .filter((block) => block.connector === "map")[0]
            .contents.filter((content) => content.code === language)[0].title,
        }}
      />
      <iframe
        className="w-full h-96 m-auto"
        src="https://www.google.com/maps/embed?pb=!3m2!1sen!2sph!4v1715363736454!5m2!1sen!2sph!6m8!1m7!1sCAoSLEFGMVFpcFByOWZsaGNtUGFBYjh2Rk9xTkt1ZUo2Ykk4SzdnTzVHTnNUenli!2m2!1d33.831521906654!2d-118.35394691067!3f25.18363010821047!4f11.42099169157558!5f0.7820865974627469"
        style={{
          border: 0,
        }}
        loading="lazy"
      ></iframe>
      <div style={{ backgroundColor: "#90C6B1" }}>
        <div className="w-full max-w-screen-xl m-auto p-16">
          <div className="flex flex-col sm:flex-row gap-16">
            <div
              className="flex-1 whitespace-pre-line"
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter((block) => block.connector === "map")[0]
                  .contents.filter((content) => content.code === language)[0]
                  .description,
              }}
            />
            <div
              className="flex-1 whitespace-pre-line"
              dangerouslySetInnerHTML={{
                __html: blocks
                  .filter((block) => block.connector === "map")[0]
                  .contents.filter((content) => content.code === language)[0]
                  .description2,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapBlock;
