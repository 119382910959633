import React from "react";
import "./style.scss";
import AdminLoginForm from "./widgets/AdminLoginForm";
import { Provider } from "react-redux";
import { store } from "../../../redux/store";

const AdminLoginScreen = () => {
  return (
    <div className="w-screen h-screen overflow-y-auto bg-gray-300">
      <AdminLoginForm />
    </div>
  );
};

export default AdminLoginScreen;
